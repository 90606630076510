import { useContext, useEffect, useState } from "react";
import Header from "../parts/Header";
import Menu from "../parts/Menu/Menu";
import "./AdminWrapper.css";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const AdminWrapper = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (userContext.user === false) {
      return navigate("/login");
    }
  }, [userContext.user]);

  return (
    <>
      <Header handleTogglePanel={() => setIsOpen(!isOpen)} />
      <div className="admin_Wrapper v1.01">
        {isOpen && (
          <Menu
            setIsOpen={setIsOpen}
            handleTogglePanel={() => setIsOpen(!isOpen)}
          />
        )}
        <div className="page">{props.children}</div>
      </div>
    </>
  );
};

export default AdminWrapper;
