export const translatioanEn ={
  navText1:'Leads',
  navText2:'Users ',
  navText3:'Segments ',
  navText4:'Calculator ',
  logOut:'log out',
  logoutText:'Are you sure you want to exit from this page?',
  yes:'Yes',
  no:"No",
  yes_with_monthly_support: 'Yes with monthly support',
  yes_with_provided_support: 'Yes with provided support',
  firstTableCell1:'ID ',
  firstTableCell2:'First Name En',
  firstTableCell3:'Mobile Number',
  firstTableCell4:'First Name Arabic',
  firstTableCell5:'Email ',
  firstTableCell6:'Name ',
  firstTableCell6:'Source ',
  firstTableTabs1:'Personal Information ',
  firstTableTabs2:'Work ',
  firstTableTabs3:'Obligations ',
  firstTableTabs4:'Advertisers ',
  firstTableTabs5:'Recommendation ',
  status:'Status ',
  datePlaceHolder: 'Action due date ',
  comment:'Comment ',
  assignee:'Assignee ' ,
  limt:'Limit ',
  limits:'Limits ',
  logs:'Logs ',
  save:'Save',
  "Create Lead": "Create Lead",
  "Personal Information": "Personal Information",
  "Mobile": "Mobile",
  "Phone Number": "Phone Number",
  "Full Name English": "Full Name English",
  "Type Full Name": "Type Full Name",
  "Full Name Arabic": "Full Name Arabic",
  "DOB": "Date of Birth",
  "DD": "Day",
  "MM": "Month",
  "YYYY": "Year",
  "Age": "Age",
  "Nationality": "Nationality",
  "Subsidized": "Subsidized",
  "First Home": "First Home",
  "Work": "Work",
  "Work Sector": "Work Sector",
  "Start of Service": "Start of Service",
  "Net Salary": "Net Salary",
  "Type Net Salary": "Type Net Salary",
  "Basic Salary": "Basic Salary",
  "Type Basic Salary": "Type Basic Salary",
  "Housing Allowance": "Housing Allowance",
  "Type Housing Allowance": "Type Housing Allowance",
  "Salary Bank": "Salary Bank",
  "Obligations": "Obligations",
  "Beneficiary has no debt": "Beneficiary has no debt",
  "Simah Defaulted": "Simah Defaulted",
  "Type": "Type",
  "Personal": "Personal",
  "Car": "Car",
  "Mortgage": "Mortgage",
  "Credit Card": "Credit Card",
  "Emkan": "Emkan",
  "Total": "Total",
  "Total Amount": "Total Amount",
  "Type Total Amount": "Type Total Amount",
  "Monthly Installment": "Monthly Installment",
  "Type Monthly Installment": "Type Monthly Installment",
  "Tenure": "Tenure",
  "Type Tenure": "Type Tenure",
  "Start of Loan": "Start of Loan",
  "Property": "Property",
  "Property not identified": "Property not identified",
  "Apartment": "Apartment",
  "Land": "Land",
  "Villa": "Villa",
  "Floor": "Floor",
  "Other": "Other",
  "Downpayment available": "Downpayment available",
  "Type Downpaymant": "Type Downpayment",
  "SAR 150,000 down payment": "SAR 150,000 down payment",
  "Leads": "Leads",
  "Settings": "Settings",
  "Simah-Defaulted":"Simah Defaulted",
  "Uptade-Lead":'Update Lead',
  "Calculator": "Calculator",
  "Users": "Users",
  "Calculations": "Calculations",
  "Retirement Age": "Retirement Age",
  "Year To retire": "Year To retire",
  "Salary": "Salary",
  "Salary After Retirement": "Salary After Retirement",
  "Total Obligations": "Total Obligations",
  "Obligations available to refinance": "Obligations available to refinance",
  "DBR": "DBR",
  "Solutions": "Solutions",
  "Sell him mortgage only": "Sell him mortgage only",
  "Refinance the previous personal loan and raise the deduction to 33%...": "Refinance the previous personal loan and raise the deduction to 33%...",
  "Max Loan Amount": "Max Loan Amount",
  "Bank": "Bank",
  "Benefit": "Benefit",
  "Tenure": "Tenure",
  "Installment": "Installment",
  "APR": "APR",
  "Max Loan Amount without liabilities": "Max Loan Amount without liabilities",
  "Monthly subsidy": "Monthly subsidy",
  "Subsidy package": "Subsidy package",
  "Property price": "Property price",
  "DP Needed": "DP Needed",
  "DP Available": "DP Available",
  "DP missing": "DP missing",
  "Extra Expenses": "Extra Expenses",
  "Personal Information": "Personal Information",
  "Work": "Work",
   Not_Sure :"Not Sure",
  "Obligations": "Obligations",
  "Advertisers": "Advertisers",
  "Recommendation": "Recommendation",
  "Attach File": "Attach File",
  "Created At": "Created At",
  "Updated At": "Updated At",
  "Status": "Status",
  "Assignee": "Assignee",
  "Change": "Change",
  "VAT_5": "5% VAT = ",
  "broker_fee_2.5": "2.5% broker fee = ",
  "bank_processing_fee": "Bank processing fee = "

}
