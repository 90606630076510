import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import './Application.css'
import { loanTypesAb, nationalityListWithOptions, salaryOptionsAb } from "./../../utils/options";
import { nationalityListWithOptionsArabic } from "./../../utils/options";
import { MilitaryArray } from "./../../utils/options";
import { alpha, styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import uq from '@umalqura/core';
import { salaryOptions } from "./../../utils/options";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import api from "./../../utils/api";
import { createSvgIcon } from "@mui/material/utils";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 
];
const months = [
  { value: 1, label: "al-Muḥarram" },
  { value: 2, label: "Ṣafar" },
  { value: 3, label: "Rabīʿ al-ʾAwwal" },
  { value: 4, label: "Rabīʿ ath-Thānī" },
  { value: 5, label: "Jumādā al-ʾŪlā" },
  { value: 6, label: "Jumādā ath-Thāniyah" },
  { value: 7, label: "Rajab" },
  { value: 8, label: "Shaʿbān" },
  { value: 9, label: "Ramaḍān" },
  { value: 10, label: "Shawwāl" },
  { value: 11, label: "Ḏū al-Qaʿdah" },
  { value: 12, label: "Ḏū al-Ḥijjah" },
];
const  mounthAb = [
  { value: 1, label: "محرم" },
  { value: 2, label: "صفر" },
  { value: 3, label: "ربيع الأول" },
  { value: 4, label: "ربيع الثاني" },
  { value: 5, label: "جمادى الأولى" },
  { value: 6, label: "جمادى الثانية" },
  { value: 7, label: "رجب" },
  { value: 8, label: "شعبان" },
  { value: 9, label: "رمضان" },
  { value: 10, label: "شوال" },
  { value: 11, label: "ذو القعدة" },
  { value: 12, label: "ذو الحجة" },
]
const simplifiedTypes = [
  { value: 1, name: 'apartment' },
  { value: 2, name: 'land' },
  { value: 3, name: 'villa' },
  { value: 4, name: 'floor' },
  { value: 5, name: 'other' }
];

const workSector = [
  { value: 1, label: "Government" },
  { value: 2, label: "Military" },
  { value: 3, label: "Private (SME)" },
  { value: 4, label: "Private (Enterprise)" },
  { value: 5, label: "Retired" },
];
const obligationsEnum = [
  { value: 1, label: "Personal" },
  { value: 2, label: "Car" },
  { value: 3, label: "Mortgage" },
  { value: 4, label: "Credit card" },
  { value: 5, label: "Emkan" },
  { value: 6, label: "Total" },
];
const commaSeparatedNumber = (str) => {
  if (!str) {
    return ''
  }
  const inputValue = str;
  const allowedCharacters = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ","];
  for (let i = 0; i < inputValue.length; i++) {
    if (!allowedCharacters.includes(inputValue[i])) {
      str = inputValue.replace(inputValue[i], "");
    }
  }
  const finalVal = parseFloat(str.split(",").join(""));
  return new Intl.NumberFormat('en').format(
    finalVal,
  )
};
const convertCommaSeparatedIntoNumber = (str) => {
  if (typeof str === 'number') {
    return str
  }
  return parseFloat(str.split(",").join(""));
}
const years = () => {
  let arr = [];
  for (let i = 1318; i <= uq(new Date())?._hy - 18; i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};  
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    left:"auto"
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
function formatNumberWithCommas(number) {
  
    return number?.toLocaleString('en-US', { useGrouping: true })
  }
const fullYear = (birthDate) => {
  let arr = [];
  for (let i = birthDate+18; i <= uq(new Date())?._hy; i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};  
const endOfLoanYear = (year) => {
  let arr = [];
  for (let i = year; i <= year+30; i++) {
    arr.push(i);
  }
  return arr;
}; 
function CreateLead() {
  const {id} = useParams()
  const {t,i18n} = useTranslation()
  console.log('id',id)
  const navigat = useNavigate()
  const [personalINformation, setPersonalINformation] = useState(null);
  const [work, setWork] = useState(null);
  const [obligationsData, setObligationData] = useState({
    defaultSimah: false,
    desiredApr: 5.89,
  });
  const [mortgage, setMorgage] = useState(null);
  const [day, setDay] = useState("");
  const [mounth, setMounth] = useState("");
  const [dbr,setDbr] = useState(NaN)
  const [apr, setApr] = useState(5.89)
  const [year, setYear] = useState("");
  const [indentified, setIndentified] = useState(false);
  const [debt, setDebt] = useState(false);
  const [responce,setresponce] = useState(null)
  const [obligations, setObligations] = useState([ {
    id: new Date(),
    amount: "",
    loanTotalAmount: "",
    // loanDuration: "",
    monthsRemaining: "",
    obligationType: "",
  },]);
  const [simah,setSimah] = useState(true)

  useEffect(()=>{
    RenderData()
  },[id])

  const calculateAge = (birthDate) => {
    const dateSplit = birthDate.split('-')
      return calculateAge1(uq(dateSplit[0],dateSplit[1],dateSplit[2])?._date)

  };
  function calculateAge1(birthDate) {
    const currentDate = new Date();
    const dob = new Date(birthDate);

    let age = currentDate.getFullYear() - dob.getFullYear();

    const currentMonth = currentDate.getMonth();
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < dob.getDate())
    ) {
      age = age - 1;
    }

    return age;
  }
  const dayFormat = (day, mounth, year) => {
    return `${year}-${mounth}-${day}`;
  };
  const changeObligationData = (id, name, value) => {
    const obj = obligations.map((i) => {
      if (i.id == id) {
        return { ...i, [name]: value };
      }
      return i;
    });
    setObligations(obj);
  };
  const collectDate = (mm, yyyy) => {
    return `${yyyy}-${mm}-${"1"}`;
  };
  console.log('obligations->',obligationsData)
  function convertCommaSeparatedToNumber(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "string" && obj[key].includes(",")) {
        obj[key] = String(obj[key].replace(/,/g, ""));
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        convertCommaSeparatedToNumber(obj[key]);
      }
    }
  }
  const normaliseWork = (work) => {
    let obj = {};
    if (work) {
      for (let key in work) {
        if (work[key]||work[key]==false) {
          obj[key] = work[key];
        }
      }
      return obj;
    } else {
      return work;
    }
  };
  const normalizeBody = (body) => {
    let obj = {};
    for (let key in body) {
      if (body[key]) {
        obj[key] = body[key];
      }
    }
    convertCommaSeparatedToNumber(obj)
    return obj;
  };
  const normaliseObligationData = (obligations) => {
    if (obligations.length&&!debt) {
      return {
        ...obligationsData,
        obligations: obligations.map((i) => {
          return {
            amount: i.amount,
            loanTotalAmount: i.loanTotalAmount,
            loanDuration: i.monthsRemaining,
            // monthsRemaining: i.monthsRemaining,
            obligationType: i.obligationType,
            desiredApr: i.desiredApr,
          };
        }),
      };
    } else {
      return obligationsData;
    }
  };
  const RenderData = () =>{
    api.get(`/leads/${id}`).then((_user) => {
      setPersonalINformation(_user)
      setDay(+_user?.dobHijri?.split('-')?.[2])
      setMounth(+_user?.dobHijri?.split('-')?.[1])
      setYear(+_user?.dobHijri?.split('-')?.[0])
      setObligations(_user?.obligations)
      setWork(_user?.works?.[0])
      setSimah(!_user?.defaultSimah)
      setMorgage(_user?.mortgages?.[0])
 
    }).catch(console.log)
  }
  useEffect(() => {
    if (day && months && year) {
      setPersonalINformation({
        ...personalINformation,
        dobHijri: dayFormat(day, mounth, year),
      });
    }
  }, [day, months, year]);
 const salaryValidation = (net,basic,alow) =>{
  console.log('sxx',net,basic,alow)
        if(+net>=+basic+alow){
          return false
        }else{
          return true
        }
 }
 const obligationsValidation = (net,obiligation) =>{
      let sum = obiligation?.reduce(
        (accumulator, currentValue) =>{
          accumulator += +currentValue?.amount
         return accumulator
        },
        0
      );
      if(net*65/100<sum){
        return true
      }else{
        return false
      }
 }

 const calculateDbr = (net,obiligation) =>{
   const number = convertCommaSeparatedIntoNumber(net ?? 0)
  let sum = obiligation?.reduce(
    (accumulator, currentValue) =>{
      accumulator += +currentValue?.amount
     return accumulator
    },
    0
  );
   return Math.ceil((sum / number) * 100)
 }
  console.log(responce)
  function handleSubmit(event) {
    const redfValue = personalINformation?.redf;
    const personalInfo = {
      ...personalINformation,
      redf: redfValue === "yes" ? true : false,
    };
    setresponce(null);
    event.preventDefault();
    api
      .post(
        "leads/calculate-lead",
        normalizeBody({
          personalInformation: normaliseWork(personalInfo),
          work: normaliseWork(work),
          obligationsData: normaliseObligationData(obligations),
          mortgage: normaliseWork(mortgage),
         
        })
      )
      .then((res) => setresponce(res))
      .catch(() => toast.error('error'))
  };
  return (
    <div className="calculatoer-container">
    <form onSubmit={handleSubmit} className="create-lead-container" style={{paddingTop:'0'}}>
      <h1 className="header-text-lead">{!+id?t('Calculator'):t('Uptade-Lead')}</h1>
      <div className="register-step">
  <div className="dov-container">
    <p>{t('DOB')}</p>
    <div className="dorp-down-container">
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          DD
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="DD"
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
          required
        >
          {days?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={mounth}
          onChange={(e) => {
            setMounth(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
          required
        >
          {(i18n.language=='en'?months:mounthAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
          required
        >
          {years()?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="age-container">
        <span>{t('Age')}</span>
        {day && year && mounth ? (
          <span>{calculateAge(dayFormat(day, mounth, year))}</span>
        ) : null}
      </div>
    </div>
  </div>
  <div className="dov-container">
    <div className="dorp-down-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Nationality')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Nationality')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Nationality"
            value={personalINformation?.nationality || ''}
            onChange={(e) =>{
              if(e.target.value!=="Saudi"){
              setPersonalINformation({
                ...personalINformation,
                nationality: e.target.value,
                redf:false
              })
            }else{
              setPersonalINformation({
                ...personalINformation,
                nationality: e.target.value,
              })
            }
          }
            }
            required
          >
            {(i18n.language=='en'?nationalityListWithOptions:nationalityListWithOptionsArabic)?.map((i) => (
              <MenuItem value={i.label}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Subsidized')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Subsidized')}
          </InputLabel>
          <Select
            size="small"
            required
            label="Subsidized"
            value={personalINformation?.nationality!=='Saudi'?false:personalINformation?.redf==null?'':personalINformation?.redf}
            disabled={personalINformation?.nationality!=='Saudi'}
            onChange={(e) =>
              setPersonalINformation({
                ...personalINformation,
                redf: e.target.value,
              })
            }
          >
           <MenuItem value="yes">{t('yes')}</MenuItem>
            {/* Hidden By Management */}
          {/* <MenuItem value="yes_with_monthly_support">{t('yes_with_monthly_support')}</MenuItem>
          <MenuItem value="yes_with_provided_support">{t('yes_with_provided_support')}</MenuItem> */}
          <MenuItem value="no">{t('no')}</MenuItem>
            {/* <MenuItem value={"false"}>{t('Not_Sure')}</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('First Home')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('First Home')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="First Home"
            value={personalINformation?.firstMortgage==null?'':personalINformation?.firstMortgage}
            onChange={(e) =>
              setPersonalINformation({
                ...personalINformation,
                firstMortgage: e.target.value,
              })
            }
            required
          >
           <MenuItem value={true}>{t('yes')}</MenuItem>
            <MenuItem value={'false'}>{t('no')}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  </div>
  <hr />
</div>
<div className="register-step">
  <div className="dov-container">
    <div className="dorp-down-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Work Sector')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Work Sector')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Work Sector"
            value={work?.workSector || ''}
            onChange={(e) =>
              setWork({ ...work, workSector: e.target.value })
            }
            required
          >
            {workSector?.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {work?.workSector == 2 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap:"8px"
    
          }}
        >
          <p>{t('Rank')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Rank')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Rank"
              value={work?.militaryRank || ''}
              onChange={(e) =>
                setWork({ ...work, militaryRank: e.target.value })
              }
              required
            >
              {MilitaryArray?.map((i) => (
                <MenuItem value={i.value}>{i.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}
      {work?.workSector == 2 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap:"8px"

          }}
        >
          <p>{t('Pilote')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Pilote')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Pilote"
              value={work?.isPilot ? 'yes' : 'no'}
              onChange={(e) =>
                setWork({ ...work, isPilot: !!(e.target.value === 'yes') })
              }
              required
            >
              <MenuItem value="yes">{t('Yes')}</MenuItem>
              <MenuItem value="no">{t('No')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  </div>
  <div style={{display:'flex',gap:'12px',width:'550px'}}> 
    <div className="dov-container" >
    <p>{t('Start of Service')}</p>
    <div className="dorp-down-container" style={{ maxWidth: "300px" }}>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={work?.jobStartDateHijri?.split("-")?.[1] || ''}
          onChange={(e) =>
            setWork({
              ...work,
              jobStartDateHijri: collectDate(
                e.target.value,
                work?.jobStartDateHijri?.split("-")?.[0]
              ),
            })
          }
          required
        >
          {(i18n.language=='en'?months:mounthAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={work?.jobStartDateHijri?.split("-")?.[0] || ''}
          onChange={(e) =>
            setWork({
              ...work,
              jobStartDateHijri: collectDate(
                work?.jobStartDateHijri?.split("-")?.[1],
                e.target.value
              ),
            })
          }
          required
        >
          {fullYear(new Date(personalINformation?.dobHijri).getFullYear())?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      
    </div>
    
  </div>
  <div className="dov-container" >
    <p>{t('Salary Bank')}</p>
    <div className="dorp-down-container" style={{ maxWidth: "150px" }}>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          {t('Salary Bank')}
        </InputLabel>
        <Select
        sx={{width:"100%"}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Salary Bank"
          size="small"
          value={work?.salaryBank || ''}
          onChange={(e) =>
            setWork({ ...work, salaryBank: e.target.value })
          }
          required
        >
          {(i18n.language=='en'?salaryOptions:salaryOptionsAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  </div>
  </div>
  <div className="dorp-down-container">
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Net Salary')}
      </InputLabel>
      <BootstrapInput
                      onWheel={(e) => e.target.blur()}
        placeholder={t('Type Net Salary')}
        value={work?.netSalary || ''}
                onChange={(e) => setWork({ ...work, netSalary: commaSeparatedNumber(e.target.value) })}
        id="bootstrap-input"
                type="string"
        required
      />
    </FormControl>
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Basic Salary')}
      </InputLabel>
      <BootstrapInput
                      onWheel={(e) => e.target.blur()}
        placeholder={t('Type Basic Salary')}
        value={work?.basicSalary || ''}
                onChange={(e) => setWork({ ...work, basicSalary: commaSeparatedNumber(e.target.value) })}
        id="bootstrap-input"
        step={0}
                type="string"
        required
      />
    </FormControl>
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Housing Allowance')}
      </InputLabel>
      <BootstrapInput
                      onWheel={(e) => e.target.blur()}
        placeholder={t('Type Housing Allowance')}
        value={work?.housingAllowance || ''}
        onChange={(e) =>
          setWork({ ...work, housingAllowance: commaSeparatedNumber(e.target.value) })
        }
        id="bootstrap-input"
                type="string"
        required
      />
    </FormControl>
  </div>
  {salaryValidation(
    convertCommaSeparatedIntoNumber(work?.netSalary || 0),
    convertCommaSeparatedIntoNumber(work?.basicSalary || 0),
    convertCommaSeparatedIntoNumber(work?.housingAllowance || 0) 
  ) ? (
    <span style={{ color: "red" }}>
      {t(
        'Net salary can’t be less than sum of basic salary and allowances'
      )}
    </span>
  ) : null}

  <hr />
</div>

<div className="register-step" style={{ maxWidth: "900px" }}>
  <div className="check-container">
    <FormControlLabel
      value={debt}
      control={
        <Checkbox
          onChange={(e) => {
            setDebt(!debt);
            if (debt) {
              setObligationData({ defaultSimah: false });
            } else {
              setObligationData(null);
            }
          }}
        />
      }
      label={t('Beneficiary has no debt')}
      labelPlacement="end"
    />
    {!debt ? (
      <FormControlLabel
        value={simah}
        control={
          <Checkbox
            checked={simah == false}
            onChange={(e) => {
              setSimah(!simah);
              setObligationData({
                ...obligationsData,
                defaultSimah: simah,
              });
            }}
          />
        }
        label={t('Simah-Defaulted')}
        labelPlacement="end"
      />
    ) : null}
  </div>

  <div className="dov-container">
    {!debt
      ? obligations?.map((i) => (
        <div className="obligation-container">
             <div
            className="dorp-down-container"
            style={{ alignItems: "end", width: "100% ", maxWidth:'none',flexDirection:"column",gap:'7px'}}
          >
     <div className="row-fixer">
           <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              
                gap:'10px'
              }}
            >
              <p className="label">{t('Type')}</p>
              <FormControl sx={{width:'100%'}}>
                <InputLabel size="small" id="demo-simple-select-label">
                  {t('Type')}
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t('Nationality')}
                  value={i?.obligationType}
                  onChange={(e) =>
                    changeObligationData(
                      i.id,
                      "obligationType",
                      e.target.value
                    )
                  }
                >
                  {(i18n.language=='en'?obligationsEnum:loanTypesAb)?.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* Removed by Management in this ticket: https://munaaseb.atlassian.net/browse/MUN-377 */}
            {/* Re Implemented this field on 7 July-2024  Reason:This field cases the missing data in API response */}
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Total Amount')}
              </InputLabel>
              <BootstrapInput
                onWheel={(e) => e.target.blur()}
                placeholder={t('Type Total Amount')}
                  type="string"
                value={i?.loanTotalAmount}
                onChange={(e) =>
                  changeObligationData(i.id, "loanTotalAmount", commaSeparatedNumber(e.target.value))
                }
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Monthly Installment')}
              </InputLabel>
              <BootstrapInput
                 onWheel={(e) => e.target.blur()}
                placeholder={t('Type Monthly Installment')}
                  type="string"
                value={i?.amount}
                onChange={(e) =>
                  changeObligationData(i.id, "amount", commaSeparatedNumber(e.target.value))
                }
                id="bootstrap-input"
              />
            </FormControl>
            {/* Removed by Management in this ticket: https://munaaseb.atlassian.net/browse/MUN-377 */}
            {/* <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Tenure')}
              </InputLabel>
              <BootstrapInput
                 onWheel={(e) => e.target.blur()}
                placeholder={t('Type Tenure')}
                value={i?.loanDuration}
                onChange={(e) =>
                  changeObligationData(i.id, "loanDuration", e.target.value)
                }
                type="number"
                id="bootstrap-input"
              />
            </FormControl> */}
           </div>
            <div className="dov-container">
              <p className="label">{t('Remaining months')}</p>
              <div
                className="dorp-down-container"
                style={{ maxWidth: "300px" }}
              >
                <FormControl fullWidth>
                  <BootstrapInput
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="MM"
                    size="small"
                    type='number'
                    value={i?.monthsRemaining}
                    onChange={(e) =>
                      changeObligationData(
                        i.id,
                        "monthsRemaining",
                        e.target.value
                      )
                    }
                  >
                  </BootstrapInput>
                </FormControl>

              </div>
            </div>
         
          </div>
          <DeleteOutlineIcon
              sx={{ cursor: "pointer" }}
              color="error"
              onClick={() =>
                setObligations(obligations.filter((item) => item.id !== i.id))
              }
            />
        </div>
         
        ))
      : null}
  </div>
  {obligationsValidation(
    convertCommaSeparatedIntoNumber(work?.netSalary || 0),
    obligations
  ) ? (
    <span style={{ color: "red" }}>
      {t('Sum of monthly installments can’t exceed 65% of salary in case mortgage available and 33% for personal loans')}
    </span>
  ) : null}
  <div className="Add-Button-container">
    {!debt ? (
      <PlusIcon
        color="secondary"
        style={{ cursor: "pointer" }}
        onClick={() =>
          setObligations([
            ...obligations,
            {
              id: new Date(),
              amount: "",
              loanTotalAmount: "",
              // loanDuration: "",
              monthsRemaining: "",
              obligationType: "",
            },
          ])
        }
      />
    ) : null}
  </div>
  {!debt?<>
  <span>Current DBR is {calculateDbr(work?.netSalary,obligations)||null} %</span>
  <FormControl variant="standard">
        <InputLabel shrink htmlFor="bootstrap-input">
          {t('DBR')}
        </InputLabel>
        <BootstrapInput
          placeholder={t('DBR')}
          value={dbr|| ''}
          onChange={(e) =>  
             {setDbr(e.target.value);
            setObligationData({
              ...obligationsData,
              dbr:+e.target.value/100
            });
          }}
          id="bootstrap-input"
          type="number"
        />
      </FormControl>
  <span>Current APR  is {calculateDbr(work?.netSalary, obligations) || null} %</span>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            {t('APR')}
          </InputLabel>
          <BootstrapInput
            placeholder={t('APR')}
            value={apr || ''}
            onChange={(e) => {
              setApr(e.target.value);
              setObligationData({
                ...obligationsData,
                desiredApr: Number(e.target.value)
              });
            }}
            id="bootstrap-input"
            type="number"
          />
        </FormControl>
      {
    dbr &&
    calculateDbr(work?.netSalary, obligations) &&
    (calculateDbr(work?.netSalary, obligations) >= dbr && calculateDbr(work?.netSalary, obligations) <= 65) ? (
        <span className="errormsg">
            Desired DBR should be in between {calculateDbr(work?.netSalary, obligations)}% and 65%
        </span>
    ) : null
}  </>:null}

  <hr style={{ width: "100%" }} />
</div>

<div className="register-step" style={{ maxWidth: "560px" }}>
  <div className="check-container">
    <FormControlLabel
      value={true}
      control={
        <Checkbox
          onChange={(e) => {
            setIndentified(!indentified);
            setMorgage(null);
          }}
        />
      }
      label={t('Property not identified')}
      labelPlacement="end"
    />
  </div>
  {!indentified ? (
    <div className="container">
      <div className="dov-container">
        <div
          className="dorp-down-container"
          style={{ alignItems: "start", paddingBottom: "20px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap:'8px'
            }}
          >
            <p className="label">{t('Propert Type')}</p>
            <FormControl fullWidth >
              <InputLabel size="small" id="demo-simple-select-label">
                {t('Propert Type')}
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('Nationality')}
                value={mortgage?.type || ''}
                onChange={(e) =>
                  setMorgage({
                    ...mortgage,
                    type: e.target.value,
                  })
                }
              >
                {simplifiedTypes?.map((i) => (
                  <MenuItem value={i.value}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Property price')}
            </InputLabel>
            <BootstrapInput
              onWheel={(e) => e.target.blur()}
              placeholder={t('Type Amount')}
              value={mortgage?.propertyPrice || ''}
              onChange={(e) =>
                setMorgage({ ...mortgage, propertyPrice: commaSeparatedNumber(e.target.value) })
              }
                      type="string"
              id="bootstrap-input"
            />
        {mortgage?.propertyPrice<100000?<span style={{ color: "red" ,fontSize:'12px',position:'absolute',bottom:'-40px'}}>
      {t('Property price can’t be less than SAR 100,000')}
    </span>:null}
          </FormControl>
          <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input">
            {t('Downpayment available')}
          </InputLabel>
          <BootstrapInput
            onWheel={(e) => e.target.blur()}
            placeholder={t('Type Downpayment')}
            value={mortgage?.downPayment || ''}
            onChange={(e) =>
              setMorgage({ ...mortgage, downPayment: commaSeparatedNumber(e.target.value) })
            }
                      type="string"
            id="bootstrap-input"
          />
          {+mortgage?.propertyPrice < +mortgage?.downPayment?<span style={{ color: "red" ,fontSize:'12px',position:'absolute',bottom:'-40px',marginBottom:'5px'}}>
      {t('Downpayment can’t be more than Property price')}
    </span>:null}
        </FormControl>
        </div>
 
      </div>
      <p>
   
      </p>
    </div>
  ) : null}
  <div className="Add-Button-container">
    <Button variant="contained" type="submit" color="success">
      {!+id ? t('Calculate') : t('Update Lead')}
    </Button>
  </div>
  <hr />
</div>
    </form>
    {responce&&<div className="responce-container">
    <div className='row-data'>
        <div>
          <p>{t('Age')}</p>
          <p>{calculateAge1(responce?.personalInformation?.dobGreg)}</p>
        </div>
        <div>
          <p>{t('Retirement Age')}</p>
          <p>{responce?.score?.response?.RetirementAge||'N/A'}</p>
        </div>
        <div>
          <p>{t('Year To retire')}</p>
          <p>{(responce?.score?.response?.RetirementAge - calculateAge1(responce?.personalInformation?.dobGreg))&&(responce?.score?.response?.RetirementAge - calculateAge1(responce?.personalInformation?.dobGreg))>0?responce?.score?.response?.RetirementAge - calculateAge1(responce?.personalInformation?.dobGreg):'N/A'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Salary')}</p>
          <p>{formatNumberWithCommas(+responce?.work?.netSalary)||'N/A'}</p>
        </div>
        <div>
          <p>{t('Salary After Retirement')}</p>
          <p>{responce?.score?.response?.salaryAfterRetierement?formatNumberWithCommas(responce?.score?.response?.salaryAfterRetierement):responce?.work?.basicSalary?formatNumberWithCommas(+responce?.work?.basicSalary):'N/A'}</p>
        </div>
      </div>
          <div className='row-data'>
        <div>
          <p>{t('Total Obligations')}</p>
              {responce?.score?.response?.totalObligations ? (
                <p>
                  {formatNumberWithCommas(
                    responce?.score?.response?.totalObligations
                  ) || "N/A"}
                </p>
              ) : (
                <p>N/A</p>
              )}
            </div>
        <div>
          <p>{t('Obligations available to refinance')}</p>
              {responce?.score?.response?.obligationsToRefinance === 0 ? (
                <p>0</p>
              ) : responce?.score?.response?.obligationsToRefinance ? (
                <p>
                  {formatNumberWithCommas(
                    responce?.score?.response?.obligationsToRefinance
                  ) || "N/A"}
                </p>
              ) : (
                "N/A"
              )}
            </div>
        <div>
          <p>{t('DBR')}</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.userDBR)||0}%</p>
        </div>
      </div>
          <p>
            {t("Solutions")} ({responce?.score?.response?.Solutions?.length})
          </p>
          {responce?.score?.response?.Solutions?.length?responce?.score?.response?.Solutions?.map((solution, index) => (
        <div className='solutions' key={index}>
          <p>{solution?.key}</p>
          <span>{solution?.descEn}</span>
          <span>{solution?.descAr}</span>
        </div>
      )):<span>No Solutions available</span>}
      <div className='row-data'>
        <div>
          <p>{t('Max Loan Amount')}</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limit : null)||'N/A'}</p>
        </div>
        <div>
          <p>{t('Bank')}</p>
          <p>{responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.bank : 'N/A'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Tenure')}</p>
          <p>{responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.Tenure/12 : 0} Years</p>
        </div>
       
        <div>
          <p>{t('APR')}</p>
          <p>{responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.rate:0} %</p>
        </div>
      </div>
      <div className='row-data'> 
      {responce?.score?.response?.Limit ?Object.entries(Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.morthgageGraphic)?.map(i=> 
         <div>
          <p>{t('Installment')} ({i?.[0]})</p>
          <p>{formatNumberWithCommas(i?.[1])||'N/A'}</p>
        </div>):null}
    
        </div>
      <div className='row-data'>
        <div>
          <p>{t('Max Loan Amount without liabilities')}</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limitWithoutObligations : 'N/A')}</p>
        </div>
      </div>
          <div className='row-data'>
        <div>
          <p>{t('Monthly subsidy')}</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.subsidy)||'N/A'}</p>
        </div>
        <div>
          <p>{t('Subsidy package')}</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.packageAmount)||'N/A'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Property price')}</p>
          <p>{formatNumberWithCommas(+responce?.mortgage?.propertyPrice)||'N/A'}</p>
        </div>
        <div>
          <p>{t('DP Needed')}</p>
          <p>{formatNumberWithCommas(+responce?.mortgage?.propertyPrice * (responce?.score?.response?.downPaymentPercent/100))||'N/A'}</p>
        </div>
        <div>
          <p>{t('DP Available')}</p>
          <p>{formatNumberWithCommas(+responce?.mortgage?.downPayment)||'N/A'}</p>
        </div>
        <div>
          <p>{t('DP missing')}</p>
          <p>{formatNumberWithCommas(((+responce?.mortgage?.propertyPrice *  (responce?.score?.response?.downPaymentPercent/100)) - +responce?.mortgage?.downPayment)>0?+responce?.mortgage?.propertyPrice *  (responce?.score?.response?.downPaymentPercent/100) - +responce?.mortgage?.downPayment:0)||'N/A'}</p>
        </div>
        <div>
          <p>{t('Property price')} (10% DP)</p>
          <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.propertyPrice10DP : null)||'N/A'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Extra Expenses')}</p>
          <p>SAR {formatNumberWithCommas(+responce?.score?.response?.BankFee +responce?.score?.response?.BrokerCheck + responce?.score?.response?.Vat)||'N/A'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
              <p>
                {t("VAT_5")}
                {responce?.score?.response?.Vat ||
                responce?.score?.response?.Vat == 0
                  ? responce?.score?.response?.Vat
                  : "N/A"}
              </p>
              <p>{t('broker_fee_2.5')}{formatNumberWithCommas(responce?.score?.response?.BrokerCheck)||'N/A'}</p>
          <p>{t('bank_processing_fee')}{formatNumberWithCommas(responce?.score?.response?.BankFee)||'N/A'}</p>
        </div>
      </div>    
      </div>}
    </div>
  );
}

export default CreateLead;
